import { useState } from "react";
import { Button } from "antd";
import LoansClosingCosts from "./LoansClosingCosts";

const ClosingCostsDetails = () => {
	const [closingCostsOpen, setClosingCostsOpen] = useState<boolean>(false)

	return (
		<div>
			<LoansClosingCosts open={closingCostsOpen} onCancel={() => setClosingCostsOpen(false)} />
			<Button type="default" block={false} onClick={() => setClosingCostsOpen(true)}>
				Details
			</Button>
		</div>
	)
}

export default ClosingCostsDetails