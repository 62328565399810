import React from 'react'
import { Modal, ModalFuncProps, Space, Typography } from 'antd';
import '../style.less'
import ClosingCostForm from './ClosingCostForm';

interface IClosingCostModalProps extends ModalFuncProps {
  closingCostId?: number|undefined;
}

const ClosingCostModal: React.FC<IClosingCostModalProps> = ({ open, onCancel, closingCostId }) => (
  <Modal footer={null} open={open} onCancel={onCancel} maskClosable>
    <Space style={{ marginBottom: '10px' }} direction="vertical" size={16}>
      <Typography.Title level={2} style={{ color: 'black' }}>
        Add Closing Cost Option
      </Typography.Title>

      <ClosingCostForm
        open={open}
        onCancel={onCancel}
        closingCostId={closingCostId}
      />
    </Space>
  </Modal>
)

ClosingCostModal.defaultProps = {
  closingCostId: undefined,
};

export default ClosingCostModal