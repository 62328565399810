import React, { useState } from 'react';
import { Modal, ModalProps, Table, Button, Popover } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import Form from 'antd/es/form';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useContextNotifications } from '../../../../context/notifications';
import { ILoan } from './closingCostsDetails.types';
import useClosingCostsTable from './hooks/useClosingCostsTable';
import ClosingCostFormPopover from './ClosingCostFormPopover';
import { useContextReports } from '../../../../context/reports';

const LoansClosingCosts: React.FC<ModalProps> = ({ open, onCancel }) => {
  const form = Form.useFormInstance();
  const loans: ILoan[] = useWatch('loans', form);
  const [exportingPdf, setExportingPdf] = useState<boolean>(false);
  const { openNotification } = useContextNotifications();
  const [updatingKey, setUpdatingKey] = useState<string | null>(null);
  const [addPopoverOpen, setAddPopoverOpen] = useState<boolean>(false);
  const { reportId, getReportClosingCosts, getReportLoanClosingCosts } = useContextReports();

  const { columns, dataSource, fail, calculateColumnTotals } = useClosingCostsTable(
    updatingKey,
    setUpdatingKey,
  );

  const handleExportPdf = () => {
    setExportingPdf(true);

    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();

      const pdfColumns = columns.map((col) => {
        let headerText = '';

        if (typeof col.title === 'string') {
          headerText = col.title;
          // @ts-ignore - title is not a string
        } else if (typeof col.title === 'object' && col.title.props?.children) {
          // @ts-ignore - title is not a string
          const { children } = col.title.props;

          if (typeof children === 'string') {
            headerText = children;
          } else if (Array.isArray(children)) {
            headerText = children.join(' ');
          } else {
            headerText = 'Unknown Column';
          }
        } else {
          headerText = 'Unknown Column';
        }

        return { header: headerText, dataKey: col.key };
      });

      const pdfRows = dataSource.map((row) => {
        const transformedRow: Record<string, string> = {};

        pdfColumns.forEach((col) => {
          const value = row[col.dataKey as keyof typeof row];

          if (col.dataKey === 'text') {
            transformedRow[col.dataKey] = String(value || '');
          } else {
            transformedRow[col.dataKey] = value?.amount
              ? `$${value.amount.toLocaleString()}`
              : '$0';
          }
        });

        return transformedRow;
      });

      const totalsRow: Record<string, string> = {};

      pdfColumns.forEach((col) => {
        if (col.dataKey === 'text') {
          totalsRow[col.dataKey] = 'Total';
        } else {
          const loanId = parseInt(col.dataKey.split('_')[1], 10);
          const total = calculateColumnTotals ? calculateColumnTotals(loanId) : 0;

          totalsRow[col.dataKey] = total ? `$${total.toLocaleString()}` : '$0';
        }
      });
      pdfRows.push(totalsRow);

      autoTable(doc, {
        head: [pdfColumns.map((col) => col.header)],
        body: pdfRows.map((row) =>
          pdfColumns.map((col) => row[col.dataKey] || '')
        ),
        styles: { cellPadding: 5, fontSize: 10 },
        headStyles: {
          fillColor: [30, 12, 104], // Custom header color (RGB)
          textColor: [255, 255, 255], // Custom text color (White)
          fontStyle: 'bold',
        },
        theme: 'striped',
        didParseCell: (data) => {
          // This is for making bold the first column
          if (data.column.index === 0) {
            // eslint-disable-next-line no-param-reassign
            data.cell.styles.fontStyle = 'bold';
          }

          // This is for making bold the bottom (total) row
          if (data.row.index === pdfRows.length - 1) {
            // eslint-disable-next-line no-param-reassign
            data.cell.styles.fontStyle = 'bold';
          }
        }
      });

      // Save the PDF
      doc.save('closing-costs.pdf');
    } catch (error) {
      openNotification?.({
        message: `Error while generating PDF: ${error}`,
        type: 'error',
      });
    } finally {
      setExportingPdf(false);
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e);
    setAddPopoverOpen(false); // Close Popover when Modal is closed
  };

  const refetchData = () => {
    getReportClosingCosts?.();
    getReportLoanClosingCosts?.(); 
  };

  return (
    <Modal
      open={open}
      title="Manage Loans Closing Costs"
      onCancel={handleCancel}
      width="80vw"
      style={{ height: '80vh' }}
      bodyStyle={{ padding: '10px' }}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          key="export"
          className="ant-btn ant-btn-primary"
          onClick={handleExportPdf}
          loading={exportingPdf}
        >
          Export PDF
        </Button>,
        reportId && (
          <Popover
            key="add"
            className="ant-btn ant-btn-primary"
            content={
              <ClosingCostFormPopover
                reportId={reportId}
                setPopoverOpen={setAddPopoverOpen}
                refetchData={refetchData}
              />
            }
            title="Add Closing Cost"
            trigger="click"
            open={addPopoverOpen}
            onVisibleChange={(visible) => setAddPopoverOpen(visible)} // Close Popover when clicking outside
          >
            <Button onClick={() => setAddPopoverOpen((prev) => !prev)}>Add Closing Cost</Button>
          </Popover>
        ),
      ]}
    >
      {fail && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          Failed to update closing cost amount
        </div>
      )}

      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={false}
        scroll={{ x: 'max-content' }}
        size="small"
        // eslint-disable-next-line react/no-unstable-nested-components
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              {loans.map((loan: ILoan) => (
                <Table.Summary.Cell index={loan.id} key={`total_${loan.id}`}>
                  <strong>
                    ${calculateColumnTotals ? calculateColumnTotals(loan.id)?.toLocaleString() || '0.00' : '0.00'}
                  </strong>
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          </Table.Summary>
        )}
        style={{
          fontSize: '12px',
        }}
      />
    </Modal>
  );
};

export default LoansClosingCosts;
