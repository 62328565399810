import { Form, Space, Typography, Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { PlusOutlined } from '@ant-design/icons';
import { ISettingItem, IUserClosingCostSettings } from '../../../../../hooks/users';
import { JsonResult } from '../../../../../types';
import ContentCard from '../../../../Common/ContentCard';
import DraggableListItem from '../../../../Common/DraggableListItem';

interface ISettingsList {
  label: string;
  description?: string;
  draggable?: boolean;
  actions?: boolean;
  items: ISettingItem[] | IUserClosingCostSettings[];
  handleUpdate: (settings: ISettingItem[]) => void;
  handleDeleteAction?: (id: string | number) => void;
  handleEditAction?: (id: string | number) => void;
  handleAddAction?: () => void;
  loading?: boolean;
}

const SettingsList = ({
  label,
  description,
  draggable,
  actions,
  items,
  handleUpdate,
  handleDeleteAction,
  handleEditAction,
  handleAddAction,
  loading
}: ISettingsList) => {
  const [form] = useForm();
  const [itemList, setItemList] = useState<ISettingItem[] | IUserClosingCostSettings[]>([]);

  useEffect(() => {
    setItemList(items.sort((a, b) => a.order - b.order));
  }, [items]);

  useEffect(() => {
    if (!itemList) return;

    const data: { [key: string]: boolean } = {};

    itemList.forEach((item) => {
      data[item.id] = !!item.active;
    });

    form.setFieldsValue(data);
  }, [itemList]);

  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return;

    const updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    const reorderedList = updatedList.map((item, index) => ({ ...item, order: index + 1 }));

    // @ts-ignore due to the different types of items
    handleUpdate(reorderedList);
    // @ts-ignore due to the different types of items
    setItemList(reorderedList);
  };

  const handleFormChange = (_: JsonResult, values: JsonResult) => {
    const updatedList = itemList.map((item) => ({ ...item, active: !!values[item.id] }));

    // @ts-ignore due to the different types of items
    handleUpdate(updatedList);
  };

  const handleItemDelete = (id: string | number) => {
    if (handleDeleteAction) {
      handleDeleteAction(id);
    }
  };

  const handleItemEdit = (id: string | number) => {
    if (handleEditAction) {
      handleEditAction(id);
    }
  };

  return (
    <Space direction="vertical" size={20}>
      <Space direction="horizontal" size="middle" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={3} style={{ fontSize: 22, fontWeight: '400'}}>
          {label}
        </Typography.Title>
        {actions && (
          <Button type="primary" onClick={handleAddAction} icon={<PlusOutlined />}>
            Add
          </Button>
        )}
      </Space>
      <ContentCard loading={loading}>
        <Form form={form} onValuesChange={handleFormChange}>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container" isDropDisabled={!draggable}>
              {(provided) => (
                <div>
                  <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                    {itemList.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.type}${item.id}`}
                        index={index}
                        isDragDisabled={!draggable}
                      >
                        {(draggableProvided) => (
                          <DraggableListItem
                            name={item.id}
                            provided={draggableProvided}
                            text={item.text}
                            draggable={draggable}
                            actions={actions}
                            handleItemEdit={handleItemEdit}
                            handleItemDelete={handleItemDelete}
                            item={item}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Form>
      </ContentCard>
    </Space>
  );
};

SettingsList.defaultProps = {
  description: undefined,
  draggable: undefined,
  loading: false,
  actions: false,
  handleDeleteAction: undefined,
  handleEditAction: undefined,
  handleAddAction: undefined
};

export default SettingsList;
