import React, { useState } from 'react';
import { Input, Popover, Spin } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ILoan } from '../closingCostsDetails.types';
import { formatCurrency, parseCurrency } from '../../../../../utils/currency';
import { useContextReports } from '../../../../../context/reports';
import { useUpdateLoansClosingCostAmount } from '../../../../../hooks/loans';
import ClosingCostFormPopover from '../ClosingCostFormPopover';
import { useLoanClosingCostDelete } from '../../../../../hooks/reports';
import { commafy } from '../../../../../utils/text';
import { useContextNotifications } from '../../../../../context/notifications';
import { IReportRow } from '../../../../../types/reports';
import { EnumClosingCostType } from '../../../../../types/settings';

const nonMutableColumnsMapping = {
  loanPoints: 'Loan Points',
  uip: 'Upfront ins. Premium',
}
const nonMutableColumns = Object.values(nonMutableColumnsMapping);

const useClosingCostsTable = (
  updatingKey: string | null,
  setUpdatingKey: (value: string | null) => void,
) => {
  const form = useFormInstance();
  const loans: ILoan[] = useWatch('loans', form);
  const {
    isBorrower,
    reportId,
    reportLoanClosingCosts,
    getReportClosingCosts,
    getReportLoanClosingCosts,
  } = useContextReports();
  const { openNotification } = useContextNotifications();
  const { fetch: fetchCostAmount } = useUpdateLoansClosingCostAmount();
  const { fetch: deleteReportClosingCost } = useLoanClosingCostDelete();
  const [amountValue, setAmountValue] = useState<number>(0);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [fail, setFail] = useState<boolean>(false);
  const tableView: Record<string, IReportRow> = useWatch('tableView', form);

  if (!reportLoanClosingCosts || !loans) return { columns: [], dataSource: [] };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;

    setAmountValue(numericValue);
    setInputValue(commafy(value));
  };

  const handleColumnClick = (key: string, amount: number) => {
    if (isBorrower) return;

    setEditingKey(key);
    setAmountValue(amount);
    setInputValue(commafy(amount));
  };

  const handleBlur = async (loanId: number, closingCostId: number) => {
    if (!editingKey) return;
    setUpdatingKey(`${loanId}_${closingCostId}`);

    try {
      const numericAmount = parseFloat(parseCurrency(String(amountValue))) || 0;

      await fetchCostAmount({ amount: numericAmount }, closingCostId);
      await refetchData();
    } catch (error) {
      setFail(true);
      openNotification?.({
        message: 'Error',
        description: 'An error occurred while updating the closing cost amount',
        type: 'error',
      });
    } finally {
      setEditingKey(null);
      setUpdatingKey(null);
      setAmountValue(0);
    }
  };

  const handleDelete = async (id: number) => {
    // @ts-ignore - reportId is not null
    await deleteReportClosingCost(String(id), { reportId });
    await refetchData();
  };

  const calculateColumnTotals = (loanId: number) => reportLoanClosingCosts
    ?.filter((item) => item.loanId === loanId)
    .reduce((total, cost) => {
      const loan = loans.find((l) => l.id === loanId);

      if (
        (cost.text === nonMutableColumnsMapping.uip && (!tableView.uip?.active || loan?.addUip)) ||
        (cost.text === nonMutableColumnsMapping.loanPoints && !tableView.loanPoints?.active)
      ) {
        return total;
      }

      return total + (cost.amount || 0);
    }, 0);

  const refetchData = async () => {
    await getReportClosingCosts?.();
    await getReportLoanClosingCosts?.();
  };

  const columns = [
    {
      title: 'Closing Costs',
      dataIndex: 'text',
      key: 'text',
      fixed: 'left' as 'left' | 'right',
      width: 222,
      render: (text: string, record: any) => {
        const allLoans = Object.keys(record)
          .filter((key) => key.startsWith('loan_'))
          .map((key) => ({
            ...record[key],
            text: record.text,
          }));

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4px 8px',
            }}
          >
            <strong style={{ fontSize: '14px' }}>{text}</strong>

            {!nonMutableColumns.includes(text) && (
              <div style={{ display: 'flex', gap: '4px' }}>
                <Popover
                  placement="right"
                  title={<span>Update <strong>{text}</strong></span>}
                  content={
                    <ClosingCostFormPopover
                      reportId={reportId ?? ''}
                      updateRows={allLoans}
                      refetchData={refetchData}
                      isEditMode
                    />
                  }
                >
                  <EditOutlined
                    style={{ marginRight: 4, cursor: 'pointer' }}
                  />
                </Popover>
                <DeleteOutlined
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => handleDelete(record.id)}
                />
              </div>
            )}
          </div>
        );
      },
    },
    ...loans.map((loan: ILoan, i) => {
      const loanKey = `loan_${loan.id}`;
      const addressParts = loan.address?.split(';') || [];
      const title = `Option ${i + 1} - ${addressParts[0] || ''}
        ${addressParts[1] || ''}`.trim();

      return {
        title,
        dataIndex: loanKey,
        key: loanKey,
        width: 222,
        render: (value: any, record: any) => {
          const cellKey = `${record.id}_${loanKey}`;
          const closingCostId = value?.closingCostId;

          if (record.text === nonMutableColumnsMapping.uip && loan.addUip) {
            return '-'
          }

          if (editingKey === cellKey && value?.editable && value.type !== EnumClosingCostType.prepaidInterest15Days) {
            return (
              <Input
                value={inputValue}
                onChange={handleChange}
                onBlur={() => handleBlur(loan.id, closingCostId)}
                autoFocus
                prefix="$"
              />
            );
          }

          if (updatingKey === `${loan.id}_${closingCostId}`) {
            return <Spin size="small" />;
          }

          return (
            <div
              onClick={() => value?.editable
                ? handleColumnClick(cellKey, value?.amount || '0')
                : null}
              style={{ cursor: value?.editable ? 'pointer' : 'default' }}
            >
              {value !== undefined ? formatCurrency(String(value.amount)) : ''}
            </div>
          );
        },
      };
    }),
  ];

  const uniqueClosingCosts = new Map<string, any>();

  reportLoanClosingCosts.forEach((closingCost) => {
    const costKey = `${closingCost.text}`;

    if (!uniqueClosingCosts.has(costKey)) {
      uniqueClosingCosts.set(costKey, {
        id: closingCost.id,
        text: closingCost.text,
        amounts: {},
      });
    }

    const costGroup = uniqueClosingCosts.get(costKey);

    costGroup.amounts[`loan_${String(closingCost.loanId)}`] = {
      amount: closingCost.amount,
      closingCostId: closingCost.id,
      type: closingCost.type,
      editable: closingCost.editable,
    };
  });

  const dataSource = Array.from(uniqueClosingCosts.values()).map(
    (cost, index) => ({
      key: index,
      id: cost.id,
      text: cost.text,
      ...Object.fromEntries(
        Object.entries(cost.amounts).map(([loanKey, value]) => [
          loanKey,
          value,
        ])
      ),
    })
  ).filter((record) => {
    if (
      (record.text === nonMutableColumnsMapping.uip && !tableView.uip?.active) ||
      (record.text === nonMutableColumnsMapping.loanPoints && !tableView.loanPoints?.active)
    ) {
      return false;
    }

    return true;
  });


  return { columns, dataSource, fail, calculateColumnTotals };
};

export default useClosingCostsTable;
