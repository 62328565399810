import { FetchResponse, FetchSuccess } from "../types";
import { DefaultFetchError, FetchGetId, FetchUpdate, useFetchGetId, useFetchUpdate } from "./fetch";

export interface ILoansClosingCosts {
  id: number;
  loanId: number;
  text: string;
  name: string;
  type: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
}

export const useLoansClosingCostsId = (): FetchGetId<FetchResponse<ILoansClosingCosts>> =>
  useFetchGetId('loans/report-closing-costs', '', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'loansClosingCosts'
  });
  
export const useUpdateLoansClosingCostAmount = (): FetchUpdate<FetchSuccess, DefaultFetchError, { amount: number }> =>
  useFetchUpdate('loans/closing-cost/amount', '');
