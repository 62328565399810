import React, { useCallback, useEffect, useState } from 'react'
import { Button, Popover } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ISettingItem } from '../../../../../hooks/users'
import SettingsList from '../../../Profile/Settings/SettingsList'
import styles from '../../index.module.less';
import { COLORS } from '../../../../../utils/cssUtils';
import { useContextReports } from '../../../../../context/reports';

type SectionItem = {
  id: number;
  name: string;
  active: boolean;
  color: string | undefined;
  order: number;
  reportId: number;
  text: number;
  type: number;
  updatedAt: string;
  createdAt: string;
}

const SectionsList = () => {
  const form = useFormInstance();
  const [settings, setSettings] = useState<ISettingItem[]>([]);
  const sections = form.getFieldValue('reportView');
  const { isBorrower, handleReportSettingsUpdate } = useContextReports();

  useEffect(() => {
    if (!sections) return

    const sectionsList: SectionItem[] = sections
      ? (Object.values(sections) as SectionItem[])
        .filter((item) => !isBorrower || item.active)
        .filter((item) => item.name !== "welcome_message")
      : [];

    // @ts-ignore it's okay here
    const items: ISettingItem[] = sectionsList
      .map((s: SectionItem) => ({
        id: s.id,
        active: s.active,
        order: s.order,
        text: s.text,
        type: s.type,
        name: s.name
      }))

    setSettings(items);
  }, [sections])

  const handleUpdate = (items: ISettingItem[]) => {
    const updatedSections = items.map((item, index) => ({
      id: item.id,
      active: item.active,
      order: index,
      text: item.text,
      type: item.type,
      name: item.name
    }));

    updatedSections.forEach((row) => {
      form.setFieldValue(['reportView', row.name], row)
    })

    handleReportSettingsUpdate?.('reportView');
  }

  const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.White;
    e.currentTarget.style.color = COLORS.White;
  }, []);

  const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.BorderGrey;
    e.currentTarget.style.color = COLORS.BorderGrey;
  }, []);

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      showArrow={false}
      content={
        <div className={styles.popperContent}>
          <SettingsList
            label="Section List"
            draggable
            items={settings}
            handleUpdate={handleUpdate}
          />

        </div>
      }
    >
      <Button
        ghost
        type="default"
        size="large"
        // icon={<Icon component={EyeOff} />}
        style={{
          borderColor: COLORS.BorderGrey,
          color: COLORS.BorderGrey,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Sections List
      </Button>
    </Popover>
  )
}

export default SectionsList