import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Select, ModalProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ISettingItem } from '../../../../../../hooks/users';
import { EnumClosingCostType } from '../../../../../../types/settings';
import { useReportClosingCostCreate, useReportClosingCostUpdate } from '../../../../../../hooks/reports';
import { useAuth } from '../../../../../../context/auth';
import { useContextReports } from '../../../../../../context/reports';

const { Option } = Select;

interface IClosingCostModal extends ModalProps {
  closingCost?: ISettingItem | null;
  reportId: string | undefined;
}

const ReportClosingCostsModal: React.FC<IClosingCostModal> = ({
  open,
  onCancel,
  closingCost,
  reportId,
}) => {
  const { user } = useAuth();
  const [form] = useForm();
  const [editingItem, setEditingItem] = useState<ISettingItem | null>(null);
  const { fetch: createReportClosingCost } = useReportClosingCostCreate()
  const { fetch: updateReportClosingCost } = useReportClosingCostUpdate()
  const { getReportClosingCosts, getReportLoanClosingCosts } = useContextReports()

  useEffect(() => {
    if (closingCost) {
      setEditingItem(closingCost);
      form.setFieldsValue({
        text: closingCost.text,
        type: closingCost.type,
        amount: Number(closingCost.amount)
      });
    } else {
      setEditingItem(null);
      form.resetFields();
    }
  }, [closingCost, form]);

  const onEdit = async (id: string | number, values: { text: string; type: EnumClosingCostType; amount: number }) => {
    if (!reportId) return
    if (!user.id) return

    await updateReportClosingCost({
      text: values.text,
      type: values.type,
      active: true,
      reportId,
      userId: Number(user.id),
      amount: values.amount
    }, id)
  };

  const onCreate = async (values: { text: string; type: EnumClosingCostType; amount: number }) => {
    if (!reportId) return
    if (!user.id) return

    createReportClosingCost({
      text: values.text,
      type: values.type,
      active: true,
      reportId,
      userId: Number(user.id),
      amount: values.amount
    })
  };

  const handleFinish = async (values: { text: string; type: EnumClosingCostType; amount: number }) => {
    if (editingItem) {
      await onEdit(editingItem.id, values);
    } else {
      await onCreate(values);
    }
    setEditingItem(null);
    form.resetFields();

    getReportClosingCosts?.();
    getReportLoanClosingCosts?.();

    if (onCancel) {
      // @ts-ignore - TS doesn't like the onCancel prop
      onCancel()
    }
  };

  return (
    <Modal
      open={open}
      title={editingItem ? 'Edit Closing Cost' : 'Create Closing Cost'}
      onCancel={onCancel}
      footer={null}
      style={{ zIndex: 9999 }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="text"
          label="Text"
          rules={[{ required: true, message: 'Please enter the text' }]}
        >
          <Input placeholder="Text" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Please select the type' }]}
        >
          <Select placeholder="Select Type">
            <Option value={EnumClosingCostType.flatFee}>Flat Fee</Option>
            <Option value={EnumClosingCostType.percentageOfLoanAmount}>Percentage of Loan Amount</Option>
            <Option value={EnumClosingCostType.prepaidInterest15Days}>Prepaid Interest 15 Days</Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
        {({ getFieldValue }) => {
          const type = getFieldValue('type');
          const prefix =
            // eslint-disable-next-line no-nested-ternary
            type === EnumClosingCostType.flatFee
              ? '$'
              : type === EnumClosingCostType.percentageOfLoanAmount
                ? '%'
                : '';
          const isPrepaidInterest = type === EnumClosingCostType.prepaidInterest15Days;

          return (
            <>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: !isPrepaidInterest,
                    message: 'Please enter the amount',
                  },
                  {
                    type: 'number',
                    message: 'Amount must be a numeric value',
                    transform: (value) => isPrepaidInterest ? 0 : Number(value),
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  prefix={prefix}
                  disabled={isPrepaidInterest}
                  defaultValue={0}
                />
              </Form.Item>
              {isPrepaidInterest && (
                <p style={{ color: '#888', fontStyle: 'italic', marginTop: -10 }}>
                  The amount will be automatically calculated for Prepaid Interest (15 days).
                </p>
              )}
            </>
          );
        }}
      </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingItem ? 'Update' : 'Save'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ReportClosingCostsModal.defaultProps = {
  closingCost: null,
};

export default ReportClosingCostsModal;