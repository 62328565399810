import React from 'react';
import { Avatar, Typography } from 'antd';
import clsx from 'clsx';
import styles from './successStory.module.less'; 
import { SuccessStoryItemProps } from './successStories.types';

const SuccessStoryItem: React.FC<SuccessStoryItemProps> = ({ title, content, avatar }) => (
  <div className={styles.wrapper}>
    <div className={clsx(styles.item)}>
      <div className={styles.textContainer}>
        <div className={styles.avatarAndTitle}>
          <Avatar src={avatar} size={64} className={styles.avatar} />
          <Typography.Title level={4} className={styles.title}>{title}</Typography.Title>
        </div>
        <Typography.Paragraph className={styles.content}>
          "{content}"
        </Typography.Paragraph>
      </div>
    </div>
    <div className={styles.logoContainer}>
      <img src="/mortage-maker-logo.svg" alt="Mortgage Maker Logo" className={styles.logo} />
    </div>
  </div>
)

export default SuccessStoryItem;
