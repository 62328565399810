import React, { useEffect, useState } from 'react';
import SettingsList from '../SettingsList';
import { useContextUsers } from '../../../../../context/users';
import { useAuth } from '../../../../../context/auth';
import { ISettingItem } from '../../../../../hooks/users';
import ClosingCostModal from '../ClosingCostModal';

const ClosingCostList = () => {
  const [closingCostModalVisible, setClosingCostModalVisible] = useState<boolean>(false);
  const { user: { id: userId } } = useAuth();
  const {
    closingCostSettings,
    getClosingCostSettings,
    deleteClosingCostSettings,
    updateUserClosingCostOrder
  } = useContextUsers();
  const [editId, setEditId] = useState<string | number | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (closingCostSettings) return;

    getClosingCostSettings?.(userId);
  }, [closingCostSettings, getClosingCostSettings, userId]);

  const handleDelete = async (deleteId: string | number) => {
    if (deleteClosingCostSettings && getClosingCostSettings) {
      await deleteClosingCostSettings(deleteId);
      getClosingCostSettings(userId);
    }
  };

  const handleEdit = (itemId: string | number) => {
    setEditId(itemId);
    setClosingCostModalVisible(true);
  };

  const handleUpdate = async (rows: ISettingItem[]) => {
    const updateRows = rows.map(row => ({ id: row.id, name: row.name, order: row.order }));

    if (!closingCostSettings || isUpdating) return;

    setIsUpdating(true);
    try {
      if (updateUserClosingCostOrder) {
        await updateUserClosingCostOrder({ closingCosts: updateRows }, userId);

        await getClosingCostSettings?.(userId);
      }
    } catch (error) {
      console.error('Error updating closing costs:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAdd = () => {
    setEditId(null);
    setClosingCostModalVisible(true);
  };

  return (
    <React.Fragment>
      <SettingsList
        label="Closing Costs – Breakdown"
        draggable
        actions
        handleDeleteAction={handleDelete}
        handleEditAction={handleEdit}
        handleAddAction={handleAdd}
        items={closingCostSettings || []}
        handleUpdate={handleUpdate}
      />
      <ClosingCostModal
        open={closingCostModalVisible}
        onCancel={() => setClosingCostModalVisible(false)}
        closingCostId={editId ? Number(editId) : undefined}
      />
    </React.Fragment>
  );
};

export default ClosingCostList;
