import { useForm } from 'antd/es/form/Form'
import React, { useEffect } from 'react'
import { Button, Form, Input, ModalFuncProps, Select, Space } from 'antd';
import { useContextUsers } from '../../../../../context/users';
import { useAuth } from '../../../../../context/auth';
import { camelize } from '../../../../../utils/text';
import { CLOSING_COST_TYPES } from '../../../Reports/constants';
import { EnumClosingCostType } from '../../../../../types/settings';

interface IClosingCostFormProps extends ModalFuncProps {
  closingCostId?: number | undefined;
}

const ClosingCostForm: React.FC<IClosingCostFormProps> = ({ open, onCancel, closingCostId }) => {
  const [form] = useForm()
  const { user: { id: userId } } = useAuth()

  const {
    closingCostSettings,
    createClosingCostSettings,
    updateClosingCostSettings,
    getClosingCostSettings
  } = useContextUsers()

  useEffect(() => {
    if (!open) return
    form.resetFields()
  }, [open])

  useEffect(() => {
    if (!closingCostId) {
      form.resetFields();

      return
    };

    const fetchClosingCost = async () => {
      await getClosingCostSettings?.(userId)
      const closingCostSetting = closingCostSettings?.find((item) => item.id === closingCostId)

      if (closingCostSetting) {
        form.setFieldsValue({
          name: closingCostSetting.text,
          type: closingCostSetting.type,
          amount: Number(closingCostSetting.amount)
        });
      }
    };

    fetchClosingCost();
  }, [closingCostId, userId]);


  const handleSubmit = async (values: { name: string; type: string; amount: number }) => {
    if (createClosingCostSettings && getClosingCostSettings) {
      const isUpdating = !!closingCostId;
      const closingCostData = {
        name: camelize(values.name),
        type: values.type,
        text: values.name || '',
        order: 1,
        active: 1,
        userId,
        id: closingCostId,
        amount: values.amount,
      };

      if (isUpdating) {
        await updateClosingCostSettings?.(closingCostData, userId);
      } else {
        await createClosingCostSettings(closingCostData);
      }

      getClosingCostSettings(userId);
      onCancel?.();
      form.resetFields();
    }
  }

  return (
    <Form form={form} layout='vertical' onFinish={handleSubmit}>
      <Form.Item
        name='name'
        label='Name'
        rules={[
          { required: true, message: 'Please enter the name' },
          { min: 3, message: 'Name must be at least 3 characters' },
          { max: 50, message: 'Name cannot be longer than 50 characters' },
        ]}
      >
        <Input size='large' placeholder='Name' />
      </Form.Item>
      <Form.Item
        name='type'
        label='Type'
        rules={[{ required: true, message: 'Please select the type' }]}
      >
        <Select size='large' placeholder='Select Type'>
          {CLOSING_COST_TYPES.map((type) => (
            <Select.Option key={type.value} value={type.value}>
              {type.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
        {({ getFieldValue }) => {
          const type = getFieldValue('type');
          const prefix =
            // eslint-disable-next-line no-nested-ternary
            type === EnumClosingCostType.flatFee
              ? '$'
              : type === EnumClosingCostType.percentageOfLoanAmount
                ? '%'
                : '';
          const isPrepaidInterest = type === EnumClosingCostType.prepaidInterest15Days;

          return (
            <>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: !isPrepaidInterest,
                    message: 'Please enter the amount',
                  },
                  {
                    type: 'number',
                    message: 'Amount must be a numeric value',
                    transform: (value) => isPrepaidInterest ? 0 : Number(value),
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  prefix={prefix}
                  disabled={isPrepaidInterest}
                  defaultValue={0}
                />
              </Form.Item>
              {isPrepaidInterest && (
                <p style={{ color: '#888', fontStyle: 'italic', marginTop: -10 }}>
                  The amount will be automatically calculated for Prepaid Interest (15 days).
                </p>
              )}
            </>
          );
        }}
      </Form.Item>

      <Space>
        <Button type='primary' size='large' htmlType='submit'>Save</Button>
        <Button size='large' onClick={() => onCancel?.()}>Cancel</Button>
      </Space>
    </Form>
  )
}

ClosingCostForm.defaultProps = {
  closingCostId: undefined,
}

export default ClosingCostForm
