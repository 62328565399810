import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import {
  IProfile,
  IProfileUpdateParams,
  IUserSettings,
  IUserSettingsParams,
  useProfileId,
  useProfileUpdate,
  useUserSettings,
  useUserSettingsUpdate,
  IUserClosingCostSettings,
  useUserClosingCostSettings,
  useUserClosingCostDelete,
  useUserClosingCostCreate,
  IUserClosingCostBody,
  useUserClosingCostUpdate,
  useUserClosingCostOrder
} from '../hooks/users';
import { FetchResponse } from '../types';

interface UsersContext {
  settings?: IUserSettings;
  getSettings?: () => Promise<FetchResponse<IUserSettings> | null>;
  updateSettings?: (
    params: IUserSettingsParams,
    id: string | number
  ) => Promise<void> | Promise<FetchResponse<string> | null>;
  settingsLoading: boolean;
  settingsUpdateLoading: boolean;

  profile: IProfile | null;
  getProfile?: () => Promise<void>;
  updateProfile?: (params: IProfileUpdateParams, id: string) => Promise<void>;
  profileUpdateLoading: boolean;
  profileIdLoading: boolean;
  closingCostSettings?: IUserClosingCostSettings[];
  getClosingCostSettings?: (id: string | number) => Promise<FetchResponse<IUserClosingCostSettings> | null>;
  createClosingCostSettings?: (params: IUserClosingCostBody)
    => Promise<FetchResponse<IUserClosingCostSettings> | null>;
  deleteClosingCostSettings?: (id: string | number) => Promise<void>;
  updateClosingCostSettings?: (params: IUserClosingCostBody, id: string | number) => Promise<void>;
  updateUserClosingCostOrder?: (params:
    { closingCosts: { order: number; id: number }[] }, userId: string) => Promise<void>;
}

const defaultValue = {
  settings: undefined,
  getSettings: undefined,
  updateSettings: undefined,
  settingsLoading: false,
  settingsUpdateLoading: false,

  profile: null,
  getProfile: undefined,
  updateProfile: undefined,
  profileUpdateLoading: false,
  profileIdLoading: false,

  closingCostSettings: undefined,
  getClosingCostSettings: undefined,
  deleteClosingCostSettings: undefined,
  createClosingCostSettings: undefined,
  updateClosingCostSettings: undefined,
  updateUserClosingCostOrder: undefined
};

export const UsersContext = createContext<UsersContext>(defaultValue);

const UsersProvider = ({ children }: PropsWithChildren) => {
  const [profile, setProfile] = useState<IProfile | null>(null);
  const profileId = useProfileId();
  const profileUpdate = useProfileUpdate();
  const userSettings = useUserSettings();
  const userSettingsUpdate = useUserSettingsUpdate();
  const userClosingCostSettings = useUserClosingCostSettings()
  const userClosingCostDelete = useUserClosingCostDelete();
  const userClosingCostCreate = useUserClosingCostCreate();
  const userClosingCostUpdate = useUserClosingCostUpdate();
  const userClosingCostOrderUpdate = useUserClosingCostOrder();

  const getProfile = () =>
    profileId.fetch().then((data) => {
      setProfile(data?.data || null);
    });

  const getSettings = () => userSettings.fetch();
  const getClosingCostSettings = (id: string | number) => userClosingCostSettings.fetch(undefined, id);

  const updateSettings = (params: IUserSettingsParams, id: string | number) => userSettingsUpdate.fetch(params, id);

  const updateProfile = (params: IProfileUpdateParams, id: string) =>
    profileUpdate.fetch(params, id).then(() => {
      getProfile();
    });

  const createClosingCostSettings = async (params: IUserClosingCostBody):
    Promise<FetchResponse<IUserClosingCostSettings> | null> => userClosingCostCreate.fetch(params);

  const deleteClosingCostSettings = async (id: string | number): Promise<void> => {
    await userClosingCostDelete.fetch(id.toString());
  };

  const updateClosingCostSettings = async (params: IUserClosingCostBody, id: string | number): Promise<void> => {
    await userClosingCostUpdate.fetch(params, id);
  }

  const updateUserClosingCostOrder = async (params:
    { closingCosts: { order: number; id: number }[] }, userId: string): Promise<void> => {
    await userClosingCostOrderUpdate.fetch(params, userId);
  }

  return (
    <UsersContext.Provider
      value={{
        settings: userSettings.data?.data,
        updateSettings,
        settingsLoading: userSettings.loading,
        settingsUpdateLoading: userSettingsUpdate.loading,
        getSettings,
        profile,
        getProfile,
        updateProfile,
        profileUpdateLoading: profileUpdate.loading,
        profileIdLoading: profileId.loading,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closingCostSettings: userClosingCostSettings.data?.data,
        getClosingCostSettings,
        deleteClosingCostSettings,
        createClosingCostSettings,
        updateClosingCostSettings,
        updateUserClosingCostOrder
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;

export const useContextUsers = (): UsersContext => useContext(UsersContext);
