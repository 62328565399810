/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback, MouseEvent, useMemo } from 'react';
import { Button, Popover, Spin } from 'antd';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { useContextReports } from '../../../../../../context/reports';
import { ISettingItem } from '../../../../../../hooks/users';
import { COLORS } from '../../../../../../utils/cssUtils';
import styles from '../index.module.less';
import Icon, { EyeOff } from '../../../../../Common/Icon';
import ReportClosingCostsModal from './ReportClosingCostsModal';
import SettingsList from '../../../../Profile/Settings/SettingsList';
import {
  useReportClosingCostDelete,
  useReportClosingCostsListUpdate,
} from '../../../../../../hooks/reports';
import { useContextNotifications } from '../../../../../../context/notifications';

interface IReportClosingCostsProps {
  reportId: string | undefined;
}

const ReportClosingCosts: React.FC<IReportClosingCostsProps> = ({ reportId }) => {
  const form = useFormInstance();
  const { reportClosingCosts, getReportClosingCosts, getReportLoanClosingCosts } = useContextReports();
  const { fetch: deleteReportClosingCost } = useReportClosingCostDelete();
  const updateClosingCostsList = useReportClosingCostsListUpdate();
  const [visible, setVisible] = useState(false);
  const [editClosingCost, setEditClosingCost] = useState<ISettingItem | null>(null);
  const { openNotification } = useContextNotifications();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Memoize expensive computations
  const memoizedFormValues = useMemo(() => {
    if (!reportClosingCosts) return {};

    return reportClosingCosts.reduce((acc, item) => {
      acc[item.id] = item.active;

      return acc;
    }, {} as { [key: string]: boolean });
  }, [reportClosingCosts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        await getReportClosingCosts?.();
        await getReportLoanClosingCosts?.();
      } catch (err) {
        console.error('Error fetching report closing costs:', err);
        setError('Failed to fetch closing costs. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (form && memoizedFormValues) {
      form.setFieldsValue(memoizedFormValues);
    }
  }, [form, memoizedFormValues]);

  const handleDelete = async (id: string | number) => {
    try {
      await deleteReportClosingCost(String(id));
      await refetchData();
    } catch {
      openNotification?.({
        type: 'error',
        message: "Can't delete, something went wrong",
      });
    }
  };

  const handleMouseEnter = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.White;
    e.currentTarget.style.color = COLORS.White;
  }, []);

  const handleMouseLeave = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.BorderGrey;
    e.currentTarget.style.color = COLORS.BorderGrey;
  }, []);

  const handleUpdate = async (settings: ISettingItem[]) => {
    try {
      const updateRows = settings.map((row) => ({
        id: row.id,
        active: row.active,
        order: row.order,
      }));

      await updateClosingCostsList.fetch({ closingCosts: updateRows }, reportId);
      await refetchData();
    } catch {
      openNotification?.({
        type: 'error',
        message: "Can't update, something went wrong",
      });
    }
  };

  const handleEdit = (id: string | number) => {
    if (!reportClosingCosts) return;

    const cost = reportClosingCosts.find((item) => item.id === id);

    if (cost) {
      setEditClosingCost(cost);
      setVisible(true);
    }
  };

  const handleAddNew = () => {
    setEditClosingCost(null);
    setVisible(true);
  };

  const refetchData = useCallback(async () => {
    setLoading(true);
    try {
      await getReportClosingCosts?.();
      await getReportLoanClosingCosts?.();
    } catch (err) {
      console.error('Error refetching data:', err);
      setError('Failed to refresh data.');
    } finally {
      setLoading(false);
    }
  }, [getReportClosingCosts, getReportLoanClosingCosts]);

  return (
    <>
      <Popover
        placement="bottomRight"
        trigger="click"
        showArrow={false}
        content={
          <div className={styles.popperContent}>
            {loading ? (
              <Spin size="large" />
            ) : error ? (
              <div className={styles.errorMessage}>{error}</div>
            ) : reportClosingCosts ? (
              <SettingsList
                label="Report Closing Costs"
                draggable
                actions
                items={reportClosingCosts}
                handleUpdate={handleUpdate}
                handleEditAction={handleEdit}
                handleDeleteAction={handleDelete}
                handleAddAction={handleAddNew}
              />
            ) : (
              <div className={styles.noData}>No data available</div>
            )}
          </div>
        }
      >
        <Button
          ghost
          type="default"
          size="large"
          // icon={<Icon component={EyeOff} />}
          style={{
            borderColor: COLORS.BorderGrey,
            color: COLORS.BorderGrey,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Closing Costs
        </Button>
      </Popover>
      <ReportClosingCostsModal
        closingCost={editClosingCost}
        reportId={reportId}
        open={visible}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

export default ReportClosingCosts;
