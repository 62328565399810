import { Typography, Button } from 'antd';
import clsx from 'clsx';
import { Logo } from '../../Common/Icon';
import Simple from '../Simple';

import styles from './index.module.less';
import SuccessStoryCarousel from './SuccessStories/SuccessStoryCarousel';

interface Simple {
  children: React.ReactNode;
}

const Auth = ({ children }: Simple): JSX.Element => (
  <Simple>
    <div className={styles.wrapper}>
      <div className={clsx([styles.container, 'page-container'])}>
        <div className={styles.content}>
          <Logo className={styles.logo} />
          {children}
        </div>
        <div className={styles.info}>
          <Typography.Title className={styles.title}>
            Mortgage Maker Loan Originator Success Stories
          </Typography.Title>

            <SuccessStoryCarousel />  
          <div className={styles.authButtons}>
            <Button
              type="primary"
              href="https://mortgagemaker.ai/features/"
              target="_blank"
              className={styles.authButton}
              size="large"
            >
              Explore the Mortgage Maker Platform Features
            </Button>
            <Button
              type="primary"
              href="https://mortgagemaker.ai/pricing/"
              target="_blank"
              className={styles.authButton}
              size="large"
            >
              Additional Pricing Information (30-Day Free Trial)
            </Button>
            <Button
              type="primary"
              href="https://mortgagemaker.ai/resources/"
              target="_blank"
              className={styles.authButton}
              size="large"
            >
              Access Mortgage Maker’s Resource Center
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Simple>
);

export default Auth;
