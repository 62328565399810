import React, { useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { EnumClosingCostType } from '../../../../types/settings';
import {
  useLoanClosingCostUpdate,
  useReportClosingCostCreate,
  useReportLoansClosingCosts
} from '../../../../hooks/reports';
import { useAuth } from '../../../../context/auth';

const { Option } = Select;

type ClosingCostFormPopoverProps = {
  reportId: string;
  updateRows?: { text: string; amount: number; closingCostId: number; editable: boolean; type: EnumClosingCostType }[];
  setPopoverOpen?: (open: boolean) => void;
  refetchData?: () => void;
  isEditMode?: boolean;
}

const ClosingCostFormPopover: React.FC<ClosingCostFormPopoverProps> = ({
  reportId,
  updateRows,
  setPopoverOpen,
  refetchData,
  isEditMode
}) => {
  const [form] = useForm();
  const { user } = useAuth();
  const { fetch: createReportClosingCost } = useReportClosingCostCreate()
  const { fetch: updateLoanClosingCost } = useLoanClosingCostUpdate()
  const { fetch: fetchLoansClosingCosts } = useReportLoansClosingCosts();

  useEffect(() => {
    if (updateRows && updateRows.length > 0) {
      const closingCost = updateRows[0];

      form.setFieldsValue({
        text: closingCost.text,
        type: closingCost.type,
        amount: closingCost.amount
      });
    }
  }, [updateRows])

  const onEdit = async (id: string | number, values: { text: string; type: EnumClosingCostType; amount: number }) => {
    if (!reportId) return
    if (!user.id) return

    await updateLoanClosingCost({
      text: values.text,
      type: values.type,
      amount: values.amount,
      reportId
    }, id)
  };

  const onCreate = async (values: { text: string; type: EnumClosingCostType; amount: number }) => {
    if (!reportId) return
    if (!user.id) return

    await createReportClosingCost({
      text: values.text,
      type: values.type,
      active: true,
      reportId,
      userId: Number(user.id),
      amount: values.amount
    })
  };

  const handleFinish = async (values: { text: string; type: EnumClosingCostType; amount: number }) => {
    if (updateRows && updateRows.length > 0) {
      const editPromises = updateRows.map(row => onEdit(row.closingCostId, { ...values, amount: row.amount })
        .then(() => {
          form.setFieldsValue({
            ...values
          })
        }))

      await Promise.all(editPromises)
        .then(() => refetchData?.())
    } else {
      await onCreate(values);
      form.resetFields();
    }

    await fetchLoansClosingCosts(undefined, reportId)
      .then(() => {
        if (refetchData) {
          refetchData();
        }
        if (setPopoverOpen) {
          setPopoverOpen(false);
        }
      });
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Form.Item
        name="text"
        label="Text"
        rules={[{ required: true, message: 'Please enter the text' }]}
      >
        <Input placeholder="Text" />
      </Form.Item>
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: 'Please select the type' }]}
      >
        <Select placeholder="Select Type">
          <Option value={EnumClosingCostType.flatFee}>Flat Fee</Option>
          <Option value={EnumClosingCostType.percentageOfLoanAmount}>Percentage of Loan Amount</Option>
          <Option value={EnumClosingCostType.prepaidInterest15Days}>Prepaid Interest 15 Days</Option>
        </Select>
      </Form.Item>
      {!isEditMode && (
        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) => {
            const type = getFieldValue('type');
            const prefix =
              // eslint-disable-next-line no-nested-ternary
              type === EnumClosingCostType.flatFee
                ? '$'
                : type === EnumClosingCostType.percentageOfLoanAmount
                  ? '%'
                  : '';
            const isPrepaidInterest = type === EnumClosingCostType.prepaidInterest15Days;

            return (
              <>
                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[
                    {
                      required: !isPrepaidInterest,
                      message: 'Please enter the amount',
                    },
                    // {
                    //   type: 'number',
                    //   message: 'Amount must be a numeric value',
                    //   transform: (value) => Number(value),
                    // },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter Amount"
                    prefix={prefix}
                    disabled={isPrepaidInterest}
                  />
                </Form.Item>
                {isPrepaidInterest && (
                  <p style={{ color: '#888', fontStyle: 'italic', marginTop: -10 }}>
                    The amount will be automatically calculated for Prepaid Interest (15 days).
                  </p>
                )}
              </>
            );
          }}
        </Form.Item>

      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

ClosingCostFormPopover.defaultProps = {
  updateRows: [],
  setPopoverOpen: undefined,
  refetchData: undefined,
  isEditMode: false
}

export default ClosingCostFormPopover;