import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BorrowerModal from '../BorrowerModal';
import { useContextNotifications } from '../../../../context/notifications';
import { IReportShareParams, useReportShare, useReportTrackActivities } from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import Loading from '../../../Common/Loading';
import { useContextReports } from '../../../../context/reports';
import Sections from '../Sections';
import Header from '../Sections/Header';
import LoanComparisons from '../Sections/LoanComparisons';
import ShareModal from '../ShareModal';
import { useUserViewGet } from '../../../../hooks/userViews';

import styles from '../index.module.less';

interface ReportEditProps {
  isLoading: boolean;
}

const ReportEdit = ({ isLoading }: ReportEditProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { openNotification } = useContextNotifications();
  const {
    form,
    errors,
    isBorrower,
    reportId,
    setReportId,
    loans,
    handleReportUpdate,
    report,
    reportLoading,
    loansLoading,
  } = useContextReports();
  const reportShare = useReportShare();
  const reportTrackActivities = useReportTrackActivities();
  const [loading, setLoading] = useState<boolean>(false);
  const [isShareModalOpened, setIsShareModalOpened] = useState<boolean>(false);
  const location = useLocation();
  const [viewIp, setViewIp] = useState('')
  const [alreadyViewed, setAlreadyViewed] = useState<boolean>(false);
  const getView = useUserViewGet?.()

  useEffect(() => {
    if (reportId) {
      fetchData().then((data) => {
        getView.fetch({ reportId, viewIp: data.ip }).then((res) => {
          if (res && res.data.alreadyViewed) {
            setAlreadyViewed(true);
          }
        });
      });
    };
  }, [reportId])

  useEffect(() => {
    const isPreview = location.pathname.includes('/preview');

    if (!isBorrower || !id || isPreview) return;

    reportTrackActivities.fetch({ id, type: 'viewed' });
  }, []);

  const loadData = () => {
    if (!id || !setReportId) return;

    setReportId(id);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const fetchData = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();

    setViewIp(data.ip);

    return data
  };

  const handleSaveAsDraft = () => {
    setLoading(true);
    handleReportUpdate?.();
    handleReportUpdate?.('draft', () => {
      openNotification?.({ message: 'Report has been saved as Draft' });
      setLoading(false);
    });
  };

  const handleShareModalOpen = () => {
    setIsShareModalOpened(true);
  };

  const handleShareModalClose = () => {
    setIsShareModalOpened(false);
  };

  const handleReportShare = (data: JsonResult, handleClose: () => void) => {
    handleClose();

    reportShare.fetch(data as IReportShareParams).then(() => {
      openNotification?.({ message: 'Report shared successfully' });
      navigate('/reports');
    });
  };

  const handleReportGenerate = () => {
    setLoading(true);
    handleShareModalOpen();
    handleReportUpdate?.();
    handleReportUpdate?.('completed', () => {
      handleShareModalOpen();
      setLoading(false);
    });
  };

  const seePreview = () => {
    // window.location.href = `https://mortgage-review.app/reports/${id || reportId}/preview`;
    navigate(`/reports/${id || reportId}/preview`);
  };

  useEffect(() => {
    if (!errors) return;

    setLoading(false);

    errors.forEach((error) => {
      openNotification?.({ type: 'error', message: error });
    });
  }, [errors]);

  return (
    <section id="reportToPdf" className={styles.container}>
      {isBorrower && reportId && !location.pathname.includes('/preview') && !alreadyViewed
        && !getView.loading && <BorrowerModal reportId={reportId} viewIp={viewIp} alreadyViewed={alreadyViewed} />}
      <Loading absolute
        visible={loading || (!report && !!reportLoading) || (!loans && !!loansLoading)
          || isLoading || getView.loading} />
      <ShareModal
        open={isShareModalOpened}
        onCancel={handleShareModalClose}
        onOk={handleReportShare}
        data={{ reportId, link: `https://mortgage-review.app/reports/${reportId}` }}
      />
      <div id="scroll-content" className={styles.content}>
        {isBorrower && <Header />}

        <Form layout="vertical" form={form}>
          <div className="flex-col gap-20">
            <LoanComparisons headerLess={isBorrower} />
            {!!loans?.filter((loan) => Boolean(loan.hidden) === false)?.length && <Sections />}
          </div>
        </Form>
      </div>
      {!!loans?.length && !isBorrower && (
        <div className="flex-row gap-8 flex-justify-end" style={{ flexWrap: 'wrap' }}>
          <Button className="btn-default-dark-blue" type="default" size="middle" onClick={seePreview}>
            Preview
          </Button>
          <Button className="btn-default-dark-blue" type="default" size="middle" onClick={handleSaveAsDraft}>
            Save as Draft
          </Button>
          <Button type="primary" size="middle" onClick={handleReportGenerate}>
            Generate Report
          </Button>
        </div>
      )}
    </section>
  );
};

export default ReportEdit;
