import React, { useState } from 'react'
import { Col, Row, Space, Typography } from 'antd'
import SettingsList from '../Settings/SettingsList'
import { ISettingItem } from '../../../../hooks/users'
import { useContextUsers } from '../../../../context/users'
import { useAuth } from '../../../../context/auth'

const Notifications = () => {
  const { user: { id } } = useAuth();
  const [updLoading, setUpdLoading] = useState(true);
  const { settings, settingsLoading, getSettings, updateSettings } = useContextUsers()

  const handleUpdate = (type: string, rows: ISettingItem[]) => {
    setUpdLoading(false);
    updateSettings?.({ ...settings, [type]: rows }, id).finally(() => {
      getSettings?.().finally(() => setUpdLoading(true));
    });
  };

  return (
    <Row gutter={[20, 20]}>
      <Col xl={8} span={24}>
        <Space direction="vertical" size={20}>
          <SettingsList
            loading={settingsLoading && updLoading}
            items={settings?.marketNotification || []}
            label="Market notifications"
            description="Receive interest rate reprice alerts."
            handleUpdate={(rows: ISettingItem[]) => handleUpdate('marketNotification', rows)}
          />
          <SettingsList
            loading={settingsLoading && updLoading}
            items={settings?.generalNotification || []}
            label="General notifications"
            handleUpdate={(rows: ISettingItem[]) => handleUpdate('generalNotification', rows)}
          />
        </Space>
      </Col>
    </Row>
  )
}

export default Notifications