import React, { CSSProperties } from 'react';
import Icon, { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form as AntdForm, Button, Space, Switch, Typography } from 'antd';
import clsx from 'clsx';
import { NamePath } from 'rc-field-form/lib/interface';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Drag } from '../Icon';

import styles from './index.module.less';
import { ISettingItem, IUserClosingCostSettings } from '../../../hooks/users';
import { EnumClosingCostType } from '../../../types/settings';

interface ISettingsListItem extends CSSProperties {
  item: ISettingItem | IUserClosingCostSettings;
  name: NamePath;
  provided: DraggableProvided;
  text: string;
  draggable?: boolean;
  onChange?: () => void;
  actions?: boolean;
  handleItemEdit?: (id: string | number) => void;
  handleItemDelete?: (id: string | number) => void;
}

const DraggableListItem = ({
  item,
  name,
  provided,
  text,
  draggable,
  onChange,
  actions,
  handleItemEdit,
  handleItemDelete,
  ...style
}: ISettingsListItem) => (
  <div className={styles.item} ref={provided.innerRef} {...provided.draggableProps}>
    <div className={clsx(styles.row, { [styles.draggable]: draggable })} style={style}>
      {draggable && <Icon className={styles.icon} component={Drag} {...provided.dragHandleProps} />}
      <div className={styles.content}>
        <div className={styles.details}>
          <Typography className={styles.label}>{text}</Typography>
        </div>
        <div className={styles.actions}> 
          {item && (
            <div className={styles.amount}>
              <Typography className={styles.value}>{item.type === EnumClosingCostType.flatFee ? '$' : ''}
                {item.amount?.toLocaleString('en-US') || ''}
                {item.type === EnumClosingCostType.percentageOfLoanAmount ? '%' : ''}</Typography>
            </div>
          )}
          {actions && (
            <Space>
              <Button type="link" icon={<EditOutlined />} onClick={() => handleItemEdit?.(name)} />
              <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleItemDelete?.(name)} />
            </Space>
          )}
          <AntdForm.Item name={name} label="test" valuePropName="checked" noStyle>
            <Switch onChange={onChange} />
          </AntdForm.Item>
        </div>
      </div>
    </div>
  </div>
);

DraggableListItem.defaultProps = {
  draggable: false,
  onChange: undefined,
  children: null,
  handleItemEdit: undefined,
  actions: false,
  handleItemDelete: undefined,
};

export default DraggableListItem;
