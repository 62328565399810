/* eslint-disable max-len */
import React from 'react'
import { Carousel } from 'antd';
import { SuccessStoryItemProps } from './successStories.types'
import styles from './successStory.module.less';
import SuccessStoryItem from './SuccessStoryItem';

const stories: SuccessStoryItemProps[] = [
  {
    title: "John Smith - President & Founder, Modern Home Lending",
    content: `MortgageMaker.ai has streamlined my loan presentations and improved client satisfaction. The time saved
      allows me to focus on closing deals faster and providing clear, personalized loan strategies. It has been
      a total game-changer for my business!`,
    avatar: "/loan-officer-1.png"
  },
  {
    title: "Michael Brown - Mortgage Consultant",
    content: `Integrating MortgageMaker.ai into my routine has simplified report creation and improved my productivity.
      The detailed, high-quality loan presentations have directly contributed to accelerating closings and
      enhancing client interactions!`,
    avatar: "/loan-officer-2.png"
  },
  {
    title: "Antony Larson - Mortgage Specialist",
    content: `The user-friendly interface of MortgageMaker.ai makes creating accurate, professional reports effortless.
      The branding options, regular updates, and support from their team give me confidence and credit for my
      insights. It's a must-have tool!`,
    avatar: "/loan-officer-3.png"
  },
  {
    title: "Lisa Johnson - Loan Broker",
    content: `MortgageMaker.ai has simplified client management with its automated and customizable reports.
      I now spend less time on paperwork and more time fostering strong client relationships. It's a
      win-win for my business and my clients!`,
    avatar: "/loan-officer-4.png"
  }
];


const SuccessStoryCarousel = () => (
  <Carousel autoplay autoplaySpeed={1000 * 10} className={styles.carouselWrapper}>
    {stories.map((story, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={styles.carouselContentStyle}>
        <SuccessStoryItem {...story} />
      </div>
    ))}
  </Carousel>
)

export default SuccessStoryCarousel